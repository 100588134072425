import React from 'react'
import { animateScroll as scroll } from 'react-scroll'
import {
    FooterContainer,
    FooterWrap,
    FooterLinksContainer,
    FooterLinksWrapper,
    FooterLinkItems,
    FooterLinkTitle,
    FooterLink,
    SocialMedia,
    SocialMediaWrap,
    SocialLogo,
    WebsiteRights,
    SocialIcons,
    SocialIconsLink
} from './FooterElements'

const Footer = () => {
    const toggleHome = () => {
        scroll.scrollToTop();
    }
    return (
        <FooterContainer>
            <FooterWrap>
                <FooterLinksContainer>
                    <FooterLinksWrapper>
                        <FooterLinksWrapper>
                            <FooterLinkItems>
                                <FooterLinkTitle>About Us</FooterLinkTitle>
                                <FooterLink>rootinvitation@gmail.com</FooterLink>
                            </FooterLinkItems>
                        </FooterLinksWrapper>
                        <FooterLinkItems>
                            <FooterLinkTitle>About Page</FooterLinkTitle>
                            <FooterLink to="/" onClick={toggleHome}>Dashboard</FooterLink>
                            <FooterLink to="home" smooth={true} duration={500} spy={true} exact='true' offset={-80}>About</FooterLink>
                            <FooterLink to="services" smooth={true} duration={500} spy={true} exact='true' offset={-80}>Services</FooterLink>
                        </FooterLinkItems>
                    </FooterLinksWrapper>
                </FooterLinksContainer>
                <SocialMedia>
                    <SocialMediaWrap>
                        <SocialLogo to='/'>
                            Root Invitation
                        </SocialLogo>
                        <WebsiteRights>Root Invitation © 2023 All rights reserved.</WebsiteRights>
                        <SocialIcons>
                            <SocialIconsLink href="https://www.instagram.com/rootinvitation.id/" target="_blank" aria-label="Instagram">
                                <i class="fab fa-instagram"></i>
                            </SocialIconsLink>
                        </SocialIcons>
                    </SocialMediaWrap>
                </SocialMedia>
            </FooterWrap>
        </FooterContainer>
    )
}

export default Footer
