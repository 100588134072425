import React from 'react';
import { useState } from 'react';
import Dashboard from '../page/dashboard/Dashboard';
import { homeObjOne, homeObjThree, homeObjTwo } from '../page/InfoSection/Data';
import InfoSection from '../page/InfoSection/InfoSection';
import Footer from './Footer';
import Navbar from './Navbar/index';
import SidebarNav from './Sidebar/index';

const NavContainer = () => {
    const [isOpen, setIsOpen] = useState(false)

    const toggle = () =>{
        setIsOpen(!isOpen)
    }

    return (
    <>
        <SidebarNav isOpen={isOpen} toggle={toggle}/>
        <Navbar toggle={toggle}/>
        <Dashboard />
        <InfoSection {...homeObjOne} />
        <InfoSection {...homeObjTwo} />
        <InfoSection {...homeObjThree} />
        <Footer />
    </>
    )
};

export default NavContainer;
