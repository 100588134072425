import './App.css';
import React from 'react';
import {BrowserRouter as Router, Route } from 'react-router-dom';
import NavContainer from './component/NavContainer';



function App() {
  return (
    <>
    <Router>
          <Route path='/' exact component={NavContainer}/>
    </Router>
    </>
  );
}

export default App;
