import svg1 from '../../images/svg-1.svg'
import svg2 from '../../images/svg-2.svg'
import svg3 from '../../images/svg-3.svg'



export const homeObjOne = {
    id: 'home',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Introduction',
    headline: 'Root Digital Invitation',
    description: 'Bringing the beauty of traditional invitations into the digital age, bridging the past with the future.',
    buttonLabel: 'Contact Us',
    img: svg1,
    imgStart: false,
    alt: 'Laptop',
    dark: false,
    primary: false,
    darkText: true
}

export const homeObjTwo = {
    id: 'services',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Services',
    headline: 'Website RSVP Service',
    description: 'Clients will be given sentence templates and website reservation links then distributed to invited guests. The link will direct invited guests to the website to complete the RSVP',
    buttonLabel: 'IDR 3 MIO',
    img: svg2,
    imgStart: true,
    alt: 'Laptop',
    dark: true,
    primary: true,
    darkText: false
}

export const homeObjThree = {
    id: 'services',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Services',
    headline: 'Whatsapp RSVP Service',
    description: 'The client only needs to provide a database (Name and Whatsapp Number) of guests who need to be reserved. We will give you a guest attendance report and will remind guests the day before the event',
    buttonLabel: 'IDR 3,5 MIO',
    imgStart: false,
    img: svg3,
    alt: 'Laptop',
    dark: true,
    primary: true,
    darkText: true
}