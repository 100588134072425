import React from 'react'
import { animateScroll as scroll } from 'react-scroll'
import { 
    SidebarContainer, 
    Icon, 
    CloseIcon,
    SidebarMenu,
    SidebarWrapper,
    SidebarLink
} from './SidebarElements'

const SidebarNav = ({ isOpen, toggle}) => {
    const toggleHome = () => {
        scroll.scrollToTop();
    }
    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon />
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                    <SidebarLink to="/" onClick={toggleHome}>
                        Dashboard
                    </SidebarLink>
                    <SidebarLink to="home" smooth={true} duration={500} spy={true} exact='true' offset={-80}>
                        About
                    </SidebarLink>
                    <SidebarLink to="services" smooth={true} duration={500} spy={true} exact='true' offset={-80}>
                        Services
                    </SidebarLink>
                </SidebarMenu>
            </SidebarWrapper>
        </SidebarContainer>
    )
}

export default SidebarNav
