import React from 'react'
import Video from '../../video/video.mp4'
import './Dashboard.css'
function Dashboard() {
    return (
        <div className='DashboardContainer'>
            <div className='DashboardBackground'>
                <video className='VideoBackground' autoPlay loop muted src={Video} type='video/mp4' />
            </div>
            <div className="DashboardContent">
                <h1 className="DashboardTitle">Root Digital Invitation</h1>
                <p className="DashboardTitleDescription">Root Digital Invitation crafts personalized and elegant online invitations that capture the essence of special moments, making event planning effortless and memorable.</p>
                <div className="DashboardTitleButtonWrap">
                </div>
            </div>        
        </div>
    )
}

export default Dashboard
